import React, { useState, useEffect } from "react";
import { Button, Col, Form, Card } from "react-bootstrap";
import marketplaceApi from "../apis/marketplace";
import { UserContext } from '../contexts/userContext';
import serviceEventsApi from "../apis/serviceevents";
import Utilites from "./Utilities";


const Categories = () => {
    const [data, setData] = useState([])
    const [quote, setQuote] = useState({})
    const [listings, setListings] = useState([])

    const [programProducts, setProgramProducts] = useState([])


    const [category, setCategory] = useState("")
    const [sku, setSku] = useState("")
    const [brand, setBrand] = useState("")
    const [zipCode, setZipCode] = useState("")

    let { userDetails } = React.useContext(UserContext);

    // initial load of component, grab list of categories and product mappings
    useEffect(() => {
        let userSettings = Utilites.getSettings();
        
        const getListings = async () => {
            var listings = await marketplaceApi.getListings(userDetails.token, 1018);
            var productMappings = await marketplaceApi.getProductMappings(userDetails.token, 1018);
            var products = productMappings.filter(p=>!p.sku && !p.productBrand && !p.productModel).map(p => {
                p.listing = listings.find(l => l.merchantItemID === p.itemId);
                return p;
            });
            // we need to get program products for the installer amount
            setProgramProducts(await serviceEventsApi.getProgramProducts(userDetails.token, userSettings.programId));
            setListings(listings);
            setData(products);
        }
        getListings();
    }, [userDetails])

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    }
    const lookupPricing = async (e) => {
        e.preventDefault();
        setQuote(null);
        var quote = await marketplaceApi.createQuote(userDetails.token, {
            category: data[category].category,
            productBrand: brand,
            productModel: "NONE",
            itemSku: sku, // RealTruck product sku
            serviceZip: zipCode,
            details: {
                "Vehicle Make": "",
                "Vehicle Model": "",
                "Vehicle Year": -1,
                "Vehicle Bed Length": "",  // optional
                "Vehicle Body Type": "",  // optional
                "Vehicle Engine": "" // optional
            }
        });
        // find listing that the quote processed linked it to
        var listingInstance = listings.find(p=>p.merchantItemID === quote.itemId);
        console.log(listingInstance);
        // find program product by product id
        var pp = programProducts.find(p=>p.productId === listingInstance.productID);
        console.log(pp);
        if (pp){
            quote.installerAmount = pp.installerAmount;
        }
        setQuote(quote);
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    if (data === undefined) {
        return <>Still loading...</>;
    }
    return (
        <Col xs={12} md={userDetails.username === 'ICEAdmin' ? 3 : 5} className='service-dropdown border rounded'  >
            <Card style={{ border: "none" }}>
                <Card.Body>
                    <Card.Title>INSTALLATION PRICING</Card.Title>
                    <Form>
                        <Form.Select aria-label="Default select example" required style={{ textAlign: 'center', marginBottom: "16px" }} value={category} onChange={handleCategoryChange} >
                            <option value="" disabled>Select Category</option>
                            {data.map((data, i) => data.listing && data.listing.category ? (
                                <option key={i} value={i} >{data.listing.category}</option>
                            ) : null)}
                        </Form.Select>
                        <Form.Select aria-label="Default select example" required style={{ textAlign: 'center', marginBottom: "16px" }} value={brand} onChange={(e) => setBrand(e.target.value)}   >
                            <option value="" disabled>Brand</option>
                            <option value="ARE" >ARE</option>
                            <option value="BAK" >BAK</option>
                            <option value="Extang" >Extang</option>
                            <option value="Retrax" >Retrax</option>
                            <option value="Roll-N-Lock" >Roll N Lock</option>
                            <option value="Truxedo" >Truxedo</option>
                            <option value="UnderCover" >UnderCover</option>
                            <option value="Lund" >Lund</option>
                            <option value="TonnoPro" >TonnoPro</option>
                            <option value="Rugged-Ridge" >Rugged Ridge</option>
                            <option value="AMP Research" >AMP Research</option>
                            <option value="N-FAB" >N-FAB</option>
                            <option value="Go Rhino" >Go Rhino</option>
                            <option value="Bushwacker" >Bushwacker</option>
                            <option value="Husky" >Husky</option>
                            <option value="Stampede" >Stampede</option>
                        </Form.Select>
                        <Form.Control type="text" required placeholder="Part#" style={{ textAlign: 'center', marginBottom: "16px" }} value={sku} onChange={(e) => setSku(e.target.value)} />
                        <Form.Control type="number" required placeholder="Zip Code" style={{ textAlign: 'center', marginBottom: "16px" }} value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                        <Button type="submit" disabled={!(category && sku && zipCode)} variant='warning' onClick={lookupPricing} className="mb-2" style={{ backgroundColor: '#ffc712' }}>
                            Submit
                        </Button>
                        {quote && quote.productInstallationAvailable && !quote.serviceProvidersAvailable ?
                            (<p>Installation Not Available in the Selected Area</p>)
                            :
                            quote && !quote.productInstallationAvailable && !quote.serviceProvidersAvailable ?
                                (<p>Installation Not Available for the selected Product</p>)
                                :
                                quote && quote.productInstallationAvailable && quote.serviceProvidersAvailable ?
                                    (<p>Installation is available!</p>)
                                    :
                                    null}
                        {quote && quote.serviceProvidersAvailable && quote.productInstallationAvailable ? (
                            <React.Fragment>
                                <table style={{ margin: '0 auto', textAlign: 'right' }}>
                                    <tbody>
                                        <tr>
                                            <td>Regular Price:</td>
                                            <td>{formatter.format(quote.regularPrice)}</td>
                                        </tr>
                                        <tr>
                                            <td>Current Discount:</td>
                                            <td>{formatter.format(quote.discount)}</td>
                                        </tr>
                                        <tr>
                                            <td>Current Sale Price:</td>
                                            <td>{formatter.format(quote.sellPrice)}</td>
                                        </tr>
                                        <tr>
                                            <td>Installer Fee:</td>
                                            <td>{formatter.format(quote.installerAmount)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </React.Fragment>) : null}
                    </Form>
                </Card.Body>
            </Card>

        </Col>
    )
}

export default Categories